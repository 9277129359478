import Parseable from '@/misc/Parseable';
export default class CleanTimeOccurrence extends Parseable {

  public start!: string;
  public end!: string;
  public duration!: number;

  public parseToObject(): any {
    return {...this};
  }

  public static parseFromObject(object: Partial<CleanTimeOccurrence>): CleanTimeOccurrence {
    const occurrence = new CleanTimeOccurrence();
    Object.assign(occurrence, object);
    return occurrence;
  }
}
