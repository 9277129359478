





















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import WorkSession from '@/models/WorkSession';
import moment from 'moment';
import UserInitialsConfig from '@/misc/UserInitialsConfig';
import User from '@/models/User';
import RJTextField from '@/components/shared/custom-vuetify/RJTextField.vue';
import Job from '@/models/Job';
import UserInitialsComponent from '@/components/user/UserInitials.component.vue';

@Component({
  components: {RJTextField, UserInitialsComponent},
})

/**
 * This component is designed for Job- and TimeTrackingSidebar to show in the Users tab, since they are roughly the same
 */
// TODO: Is not used anymore. Remove!!
export default class EmployeeTabComponent extends Vue {


  @Prop()
  public session!: WorkSession | Job;

  @Prop()
  public isJob!: boolean;

  private userConfig: UserInitialsConfig = {showTooltip: false, showFullName: true, big: true};
  /**
   * Gets localized Duration Time from Worksession item
   */
  public getDurationHumanized(duration: number) {
    const d = moment.duration(duration);
    return `${d.hours()} ${this.$t('GENERAL.UNITS_SHORT.HOUR')}  ${(d.minutes() + '').padStart(2, '0')} ${this.$t('GENERAL.UNITS_SHORT.MINUTES')}.`;
  }


  private onUserClick(user: User | string) {
    this.$router.push({
      name: 'userDetails',
      params: {
        companyId: this.$route.params.companyId,
        userId: user instanceof User ? user.id! : user,
      },
    });
  }

}
