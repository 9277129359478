import moment from 'moment';
import WorkSession from '@/models/WorkSession';
import Job from '@/models/Job';

/**
 * Support class which provides functions to determine the status of a job
 */
export class JobStatusIdentification {

    /**
     * Identifies the status of the given job
     * @param job
     */
    public static identify(job: Job): JobStatusType {
        // check if any work sessions were started at all and if each assigned user has started a session
        const allUserStarted = job.workSessions.length > 0 && job.workSessions.length === job.cleanTime.plannedUsers.length;
        const jobDate = moment(job.cleanTimeOccurrence.start);

        if (job.cleanTime.plannedUsers.length === 0) {
            // if job has not been assigned
            return JobStatusType.NOT_PLANNED;
        }
        if (jobDate.isAfter(moment().utc())) {
            // if job is assigned but in the future
            return JobStatusType.IN_FUTURE;
        }
        if (allUserStarted && job.workSessions.every((session: WorkSession) => session.duration > 0)) {
            // if job is in the assigned & in the past and completed
            return JobStatusType.DONE;
        }
        if (allUserStarted && job.workSessions.some((session: WorkSession) => session.duration >= 0)) {
            // if job is in the assigned & in the past and at least one Work Session has been started
            return JobStatusType.IN_PROGRESS;
        }
        // if job is in the assigned & in the past and no Work Session has been started
        return JobStatusType.NOT_TOUCHED;
    }
}

/**
 * An enum which defines all available job status values
 */
export enum JobStatusType {
    NOT_PLANNED = 'NOT_PLANNED',
    IN_FUTURE = 'IN_FUTURE',
    AMBIGUOUS = 'AMBIGUOUS',
    NOT_TOUCHED = 'NOT_TOUCHED',
    DONE = 'DONE',
    CANCELLED = 'CANCELLED',
    IN_PROGRESS = 'IN_PROGRESS',
}
