






















import {Component, Prop, Vue} from 'vue-property-decorator';
import CleanTime from '@/models/CleanTime';
import Task from '@/models/Task';

@Component({components: {},
})

export default class AreaTabComponent extends Vue {

  @Prop()
  public cleanTime!: CleanTime;

  @Prop({default: null})
  public checkedTasks?: Task[];

  public isChecked(task: Task): boolean {
    if (this.checkedTasks) {
      return this.checkedTasks.map((item) => item.id).includes(task.id);
    }
    return false;
  }
}
